ion-header.static {
	z-index: 5;
}

ion-toolbar {
	--border-color: #fff;
	--border-width: 0 !important;
}

ion-button.solo-button {
	--padding-start: 6px;
	--padding-end: 6px;
}

ion-button.basket-button {
	/* width: 45px; */
	height: 41px;
}

ion-button.basket-button > div > div > .badge {
	position: absolute;
	bottom: 0;
	width: 18px;
	height: 18px;
	right: 0;
	z-index: 1;
	display: flex;
    align-items: center;
    justify-content: center;
	font-size: 10px;
	border-radius: 50%;
	border: 1px solid;
}

ion-button.basket-button > div > .basket-icon-wrapper {
	position: relative;
}

ion-toolbar.primary-toolbar {
	--min-height: 80px;
	--background: transparent no-repeat 50% 50% / auto 90%;
	background-size: auto 35px;
	background-repeat: no-repeat;
	background-position: 50%;
}

ion-button.basket-button > div > div > ion-icon {
	font-size: 30px;
}

.primary-toolbar .button {
	color: var(--ion-color-dark);
}

.primary-toolbar .image-button {
	flex: 1;
	background: transparent;
	background: transparent no-repeat 45% 50% / auto 100%;
	height: 40px;
	--ripple-color: transparent;
	margin: 0 40px !important 
	
}

.web ion-header.static {
	position: absolute;
	right: 0;
	background: transparent none;
	min-height: var(--ion-toolbar-min-height);
	height: auto;
	margin: 0;
	z-index: 6;
	pointer-events: none;
}

.web ion-header.static ion-buttons {
	pointer-events: all;
}

.web ion-toolbar.primary-toolbar {
	--background: transparent none;
}
ion-header.static.route-dashboard {
	z-index: 6;
}
.web ion-toolbar.primary-toolbar ion-buttons:first-of-type,
.web ion-toolbar.primary-toolbar ion-buttons:last-of-type,
.web ion-toolbar.dynamic-header ion-buttons:first-of-type {
	background-color: transparent;
}
.web ion-toolbar.primary-toolbar ion-buttons ion-button {
	height: 35px !important;
	width: 35px !important;
	--padding-top: 7px !important;
	--padding-end: 7px !important;
	--padding-bottom: 7px !important;
	--padding-start: 7px !important;
}
.web ion-toolbar.primary-toolbar ion-buttons {
	margin-right: 13px;
}
.web ion-toolbar.primary-toolbar ion-button.image-button {
	display: none;
}

.web ion-header.static > ion-toolbar:last-child {
	display: block;
}

.web ion-button.basket-button > div > div > ion-badge.badge-small {
	--ion-color-primary: var(--ion-color-tertiary);
	color: var(--ion-color-black);
	font-size: 9px;
	min-width: 15px;
	text-align: center;
	--padding-top: 3px;
	--padding-bottom: 3px;
	--padding-start: 3px;
	--padding-end: 3px;
	width: auto;
	height: auto;
	right: 0;
	border-radius: 8px;
	padding: 3px;
}

.web ion-button.basket-button > div > div:last-child {
	--ion-color-gray: var(--ion-color-secondary);
}

.header-title {
	text-align: center !important;
	margin-bottom: 0 !important;
	--background: transparent;
	width: 100%;
	text-transform: none !important;
	margin: 0;
}
.header-title .title {
	margin: 0 !important;
	font-weight: 300;
	font-size: 25px;
}
.white-header-title {
	color: var(--ion-color-primary);
}
.web .header-title {
	display: none;
}

.primary-toolbar .image-button-resizable {
	flex: 1;
	background: transparent;
	background: transparent no-repeat 45% 50% / auto 100%;
	height:auto;
	--ripple-color: transparent;
}

.header-scan-button {
	visibility: hidden;
}